import { ISettingWrap, ISettingBlock } from "interfaces"
import { SettingCard } from "./SettingCard"

export const SettingsContainer = ({ settingBlock }: ISettingWrap) => {
  return (
    <div className="flex flex-1 flex-col pl-6 w-full overflow-hidden h-[82vh] overflow-y-scroll">
      <div className="overflow-y-visible">
        {settingBlock.map(
          (
            { subHeader, cardDescription, settings, extras }: ISettingBlock,
            index
          ) => (
            <SettingCard
              extras={extras}
              key={index}
              index={index}
              settingsPerCard={settings}
              subHeader={subHeader}
              cardDescription={cardDescription}
            />
          )
        )}
      </div>
    </div>
  )
}
