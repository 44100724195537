import { ISettingWrap } from "interfaces"

/**
 * This json holds the setting data.
 * If you want to add new settings, you can add them to this json
 * and the code below will dynamically generate the settings page based on this json.
 */

export const allSettings: ISettingWrap[] = [
  {
    header: "pages.settings.general",
    settingBlock: [
      {
        subHeader: "pages.settings.basicSettings",
        cardDescription: "",
        settings: [],
        extras: [
          {
            name: "WorkspaceName"
          }
        ]
      },
      {
        subHeader: "pages.settings.workspaceAddressCardHeader",
        cardDescription: "pages.settings.workspaceAddressCardDescription",
        settings: [
          [
            {
              setting_metadata: {
                key: "WorkspaceAddress",
                description: "",
                data_type: "Text"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/WorkspaceAddress"
            }
          ]
        ]
      },
      {
        subHeader: "pages.settings.localizationSettings",
        cardDescription: "pages.settings.localizationSettingsDescription",
        settings: [
          [
            {
              setting_metadata: {
                key: "TimeZone",
                description: "pages.settings.TimeZoneDescription",
                data_type: "TimeZone"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/TimeZone"
            }
          ]
        ],
        extras: [
          {
            name: "Languages"
          }
        ]
      }
    ]
  },
  {
    header: "pages.settings.visit",
    module: "visit",
    category: "true",
    settingBlock: []
  },
  {
    header: "pages.settings.flow",
    module: "visit",
    settingBlock: [
      {
        // Subheader and cardDescription for each setting block
        // Add inner array in 'settings' to wrap them in a row
        subHeader: "pages.settings.visitor",
        cardDescription: "pages.settings.visitorSettingsDescription",
        settings: [
          [
            {
              setting_metadata: {
                key: "AskVisitorEmail",
                description: "pages.settings.AskVisitorEmailDescription",
                data_type: "Dropdown"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/AskVisitorEmail"
            }
          ],
          [
            {
              setting_metadata: {
                key: "AskVisitorPhone",
                description: "pages.settings.AskVisitorPhoneDescription",
                data_type: "Dropdown"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/AskVisitorPhone"
            }
          ],
          [
            {
              setting_metadata: {
                key: "TakePhoto",
                description: "pages.settings.TakePhotoDescription",
                data_type: "Boolean"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/TakePhoto"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PrintCard",
                description: "pages.settings.TurnPrintingOnDescription",
                data_type: "PrintingBoolean"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/PrintCard"
            }
          ],
          [
            {
              setting_metadata: {
                key: "AutomaticVisitorSignOut",
                description:
                  "pages.settings.AutomaticVisitorSignOutDescription",
                data_type: "Boolean"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/AutomaticVisitorSignOut"
            }
          ]
        ]
      },
      {
        subHeader: "pages.settings.preArrivalEmailSettings",
        cardDescription: "pages.settings.preArrivalEmailSettingsDescription",
        settings: [
          [
            {
              setting_metadata: {
                key: "PreArrivalEmailSubject",
                description: "pages.settings.PreArrivalEmailSubjectDescription",
                data_type: "Text"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/PreArrivalEmailSubject"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PreArrivalEmailHeaderImageUrl",
                description: "pages.settings.HeaderLogoUrlDescription",
                data_type: "Picture"
              },
              value: "false",
              endpoint:
                "/workspaces/{id}/settings/PreArrivalEmailHeaderImageUrl"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PreArrivalEmailVisitHost",
                description:
                  "pages.settings.PreArrivalEmailVisitHostDescription",
                data_type: "Boolean"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/PreArrivalEmailVisitHost"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PreArrivalEmailVisitHostEmail",
                description:
                  "pages.settings.PreArrivalEmailVisitHostEmailDescription",
                data_type: "Boolean"
              },
              value: "",
              endpoint:
                "/workspaces/{id}/settings/PreArrivalEmailVisitHostEmail"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PreArrivalEmailVisitHostPhone",
                description:
                  "pages.settings.PreArrivalEmailVisitHostPhoneDescription",
                data_type: "Boolean"
              },
              value: "",
              endpoint:
                "/workspaces/{id}/settings/PreArrivalEmailVisitHostPhone"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PreArrivalEmailVisitDateTime",
                description:
                  "pages.settings.PreArrivalEmailVisitDateTimeDescription",
                data_type: "Boolean"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/PreArrivalEmailVisitDateTime"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PreArrivalEmailVisitOrganizationAddress",
                description:
                  "pages.settings.PreArrivalEmailVisitOrganizationAddressDescription",
                data_type: "Boolean"
              },
              value: "",
              endpoint:
                "/workspaces/{id}/settings/PreArrivalEmailVisitOrganizationAddress"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PreArrivalEmailBody",
                description: "pages.settings.PreArrivalEmailBodyDescription",
                data_type: "PreArrivalEmailBody"
              },
              value: "false",
              endpoint: "/workspaces/{id}/settings/PreArrivalEmailBody"
            }
          ]
        ]
      }
    ]
  },
  {
    header: "pages.settings.notifications",
    module: "visit",
    settingBlock: [
      {
        subHeader: "pages.settings.hostNotificationSettings",
        cardDescription: "pages.settings.hostNotificationSettingsDescription",
        settings: [
          [
            {
              setting_metadata: {
                key: "SendSms",
                description: "pages.settings.SendSmsDescription",
                data_type: "Boolean"
              },
              value: "false",
              endpoint: "/workspaces/{id}/settings/sendsms"
            }
          ],
          [
            {
              setting_metadata: {
                key: "SendEmail",
                description: "pages.settings.SendEmailDescription",
                data_type: "Boolean"
              },
              value: "false",
              endpoint: "/workspaces/{id}/settings/sendemail"
            }
          ]
        ]
      },
      {
        subHeader: "pages.settings.expectedVisitEmailSettings",
        cardDescription: "pages.settings.expectedVisitEmailSettingsDescription",
        settings: [
          [
            {
              setting_metadata: {
                key: "SendExpectedEmail",
                description: "pages.settings.sendExpectedEmailDescription",
                data_type: "Boolean"
              },
              value: "false",
              endpoint: "/workspaces/{id}/settings/sendexpectedemail"
            }
          ],
          [
            {
              setting_metadata: {
                key: "SendExpectedSms",
                description: "pages.settings.sendExpectedSmsDescription",
                data_type: "Boolean"
              },
              value: "false",
              endpoint: "/workspaces/{id}/settings/sendexpectedsms"
            }
          ]
        ]
      },
      {
        subHeader: "pages.settings.lobbyNotificationSettings",
        cardDescription: "pages.settings.lobbyNotificationSettingsDescription",
        settings: [
          [
            {
              setting_metadata: {
                key: "SendLobbyNotifications",
                description: "pages.settings.SendLobbyNotificationsDescription",
                data_type: "Boolean"
              },
              value: "false",
              endpoint: "/workspaces/{id}/settings/sendlobbynotifications"
            }
          ],
          [
            {
              setting_metadata: {
                key: "LobbyEmailAddress",
                description: "pages.settings.LobbyEmailAddressDescription",
                data_type: "Email"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/lobbyemailaddress"
            }
          ],
          [
            {
              setting_metadata: {
                key: "LobbyPhoneNumber",
                description: "pages.settings.LobbyPhoneNumberDescription",
                data_type: "Text"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/lobbyphonenumber"
            }
          ]
        ]
      }
    ]
  },
  {
    header: "pages.settings.terms",
    module: "visit",
    settingBlock: [
      {
        subHeader: "pages.settings.termsOfVisit",
        cardDescription: "pages.settings.termsOfVisitDescription",
        settings: [
          [
            {
              setting_metadata: {
                key: "TermsOfVisitFi",
                description: "",
                data_type: "TextArea"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/TermsOfVisitFi"
            }
          ],
          [
            {
              setting_metadata: {
                key: "TermsOfVisitEn",
                description: "",
                data_type: "TextArea"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/TermsOfVisitEn"
            }
          ],
          [
            {
              setting_metadata: {
                key: "TermsOfVisitDk",
                description: "",
                data_type: "TextArea"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/TermsOfVisitDk"
            }
          ],
          [
            {
              setting_metadata: {
                key: "TermsOfVisitPl",
                description: "",
                data_type: "TextArea"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/TermsOfVisitPl"
            }
          ],
          [
            {
              setting_metadata: {
                key: "TermsOfVisitRo",
                description: "",
                data_type: "TextArea"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/TermsOfVisitRo"
            }
          ]
        ]
      },
      {
        subHeader: "pages.settings.privacyPolicy",
        cardDescription: "pages.settings.privacyPolicyDescription",
        settings: [
          [
            {
              setting_metadata: {
                key: "PrivacyPolicyFi",
                description: "",
                data_type: "TextArea"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/PrivacyPolicyFi"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PrivacyPolicyEn",
                description: "",
                data_type: "TextArea"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/PrivacyPolicyEn"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PrivacyPolicyDk",
                description: "",
                data_type: "TextArea"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/PrivacyPolicyDk"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PrivacyPolicyPl",
                description: "",
                data_type: "TextArea"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/PrivacyPolicyPl"
            }
          ],
          [
            {
              setting_metadata: {
                key: "PrivacyPolicyRo",
                description: "",
                data_type: "TextArea"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/PrivacyPolicyRo"
            }
          ]
        ]
      }
    ]
  },
  {
    header: "pages.settings.rooms",
    module: "rooms",
    category: "true",
    settingBlock: []
  },
  {
    header: "pages.settings.branding",
    module: "rooms",
    settingBlock: [
      {
        subHeader: "pages.settings.roomsBrandingSubHeader",
        cardDescription: "pages.settings.roomsBrandingDescription",
        settings: [
          [
            {
              setting_metadata: {
                key: "RoomsLogo",
                description: "pages.settings.RoomsLogoDescription",
                data_type: "Picture"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/RoomsLogo"
            }
          ],
          [
            {
              setting_metadata: {
                key: "RoomsBackgroundPicture",
                description: "pages.settings.RoomsBackgroundPictureDescription",
                data_type: "Picture"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/RoomsBackgroundPicture"
            }
          ]
        ]
      }
    ]
  },
  {
    header: "pages.settings.energySaver",
    module: "rooms",
    settingBlock: [
      {
        subHeader: "pages.settings.workingHoursTitle",
        cardDescription: "pages.settings.roomsPowerSaveDescription",
        settings: [
          [
            {
              setting_metadata: {
                key: "WorkingHours",
                description: "pages.settings.WorkingHoursDescription",
                data_type: "Boolean"
              },
              value: "",
              endpoint: "/workspaces/{id}/settings/WorkingHours"
            }
          ],
          [
            {
              setting_metadata: {
                key: "WorkingHoursStart",
                description: "pages.settings.WorkingHoursStartDescription",
                data_type: "Time"
              },
              value: "",
              endpoint: "workspaces/{id}/settings/WorkingHoursStart"
            }
          ],
          [
            {
              setting_metadata: {
                key: "WorkingHoursEnd",
                description: "pages.settings.WorkingHoursEndDescription",
                data_type: "Time"
              },
              value: "",
              endpoint: "workspaces/{id}/settings/WorkingHoursEnd"
            }
          ]
        ]
      }
    ]
  }
]
