import { ICustomSetting, ISettingEntity } from "interfaces"
import { SettingBuilder } from "./SettingBuilder"
import { useTranslate } from "@pankod/refine-core"
import { WorkspaceNameChange } from "./builder-components/WorkspaceNameChange"
import { WorkspaceLanguageSettings } from "./builder-components/WorkspaceLanguageSettings"
import { SETTING_CARD_EXTRAS } from "utilities/types"

/**
 * SettingCard is just a wrapper that wraps settings components to a single card on UI.
 * You can determine how many settingEntities are inside a card by modifying DefaultWorkspaceSettings.ts file.
 */
export const SettingCard = ({
  extras,
  settingsPerCard,
  index,
  subHeader,
  cardDescription
}: {
  extras?: ICustomSetting[]
  settingsPerCard: ISettingEntity[][]
  index: number
  subHeader: string
  cardDescription: string
}) => {
  const translate = useTranslate()

  const hasWorkspaceNameChange = !!extras?.find(
    (e) => e.name === SETTING_CARD_EXTRAS.WORKSPACE_NAME
  )

  const hasLanguages = !!extras?.find(
    (e) => e.name === SETTING_CARD_EXTRAS.LANGUAGES
  )

  return (
    <div
      key={index}
      className="bg-white rounded-xl px-6 pt-5 m-4 border border-gray-300"
    >
      {subHeader && (
        <>
          <h1 className="font-kanit text-xl pb-2">{translate(subHeader)}</h1>
          <div className="px-5 -mx-8 pb-4">
            {cardDescription && (
              <p className="text-gray-600 pl-3 pb-2 text-sm">
                {translate(cardDescription)}
              </p>
            )}
          </div>
        </>
      )}
      <div className="pt-4">
        {hasWorkspaceNameChange && (
          <div className="lg:flex pb-2 text-red">
            <WorkspaceNameChange />
          </div>
        )}
        {hasLanguages && (
          <div className="lg:flex pb-2 text-red">
            <WorkspaceLanguageSettings />
          </div>
        )}
        {settingsPerCard.map((settingsData: ISettingEntity[], index) => (
          <div key={settingsData[index]?.value} className="py-1">
            <div className="lg:flex pb-2">
              {settingsData.map((adjacentSetting, settingChildEntityIndex) =>
                SettingBuilder(adjacentSetting, settingChildEntityIndex)
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
