import { IMandatoryFields } from "interfaces"
import i18n from "./i18n"
import { isEmptyString } from "./string"
import { validatePassword } from "./passwordValidator"
import { phone } from "phone"

const minNameLength = 2
// Commented out to allow hosts to include numbers etc
// const regexName = /^[a-zA-ZäöüÄÖÜß]+$/

/**
 * Validate form fields
 * @returns Error strings
 */
function handleValidation(
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  mandatoryFields: IMandatoryFields,
  password: string | null = null
): string[] {
  const errors = []
  errors["firstName"] = validateFirstName(firstName)
  errors["lastName"] = validateLastName(lastName)
  if (mandatoryFields.phone || !isEmptyString(phone))
    errors["phone"] = validatePhone(phone)
  if (mandatoryFields.email || !isEmptyString(email))
    errors["email"] = validateEmail(email)
  if (password != null) {
    errors["password"] = validatePassword(password)
  }

  return errors
}

export function validateFirstName(firstName: string): any {
  let errorMessage

  if (!firstName) {
    errorMessage = i18n.t("form.validators.empty", {
      field: i18n.t("form.firstName")
    })
  } else if (firstName.length < minNameLength) {
    errorMessage = i18n.t("form.validators.minLength", {
      field: i18n.t("form.firstName"),
      length: minNameLength
    })
  }
  // Commented out to allow hosts to include numbers etc
  //   else if (firstName) {
  //     if (!firstName.match(regexName)) {
  //       errorMessage = i18n.t("form.validators.onlyLetters", {
  //         field: i18n.t("form.firstName")
  //       })
  //     }
  //   }
  return errorMessage
}

export function validateLastName(lastName: string): any {
  let errorMessage

  if (!lastName) {
    errorMessage = i18n.t("form.validators.empty", {
      field: i18n.t("form.lastName")
    })
  } else if (lastName.length < minNameLength) {
    errorMessage = i18n.t("form.validators.minLength", {
      field: i18n.t("form.lastName"),
      length: minNameLength
    })
  }
  // Commented out to allow hosts to include numbers etc
  //   else if (lastName) {
  //     if (!lastName.match(regexName)) {
  //       errorMessage = i18n.t("form.validators.onlyLetters", {
  //         field: i18n.t("form.lastName")
  //       })
  //     }
  //   }
  return errorMessage
}

export function validatePhone(phoneNumber: string): any {
  let errorMessage

  if (phoneNumber) {
    const phoneInfo = phone(phoneNumber)
    if (!phoneInfo.isValid) {
      errorMessage = i18n.t("form.validators.notValidPhoneNumber", {
        field: i18n.t("form.phone")
      })
    }
  }

  return errorMessage
}

export function validateEmail(email: string): any {
  let errorMessage

  if (!email) {
    // We're going to allow empty email
    return
  } else if (email) {
    const lastAtPos = email.lastIndexOf("@")
    const lastDotPos = email.lastIndexOf(".")

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
      )
    ) {
      errorMessage = i18n.t("form.validators.notValid", {
        field: i18n.t("form.email")
      })
    }
  }

  return errorMessage
}

const validators = {
  handleValidation,
  validateFirstName,
  validateLastName,
  validatePhone,
  validateEmail
}

export default validators
